import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { GetTicketsSoldByEvent } from "../../../../../controllers/company/EventsController";
import TicketsSoldDataTable from "./dataTable";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function TicketsSold(props)
{
    const {id} = useParams();
    const [event,setEvent] = useState(props.event);
    const [tickets,setTickets] = useState([]);

    useEffect(()=>{
        handleGetTicketsSold();
    },[]);

    const handleGetTicketsSold = async () => {
        let response = await GetTicketsSoldByEvent(id);
        if(response.success === true)
        {
            setTickets(response.data.tickets);
        }
    }

    return(
        <Container>
            {
                tickets.length !== 0 &&
                <TicketsSoldDataTable tickets={tickets}/>
            }
        </Container>
    )
}
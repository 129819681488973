import { useContext, useEffect, useState } from "react";
import { Button, Container, Divider, Grid, MenuItem, Paper, TextField, Typography } from "@mui/material";
import UserContext from "../../../navigation/context/userContext";
import CompanyProfileImages from "./images";
import CompanyInfo from "./info";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import Socials from "./Socials";
import WorkingHours from "./workingHours";
import SectionTitle from "../../../components/sectionTitle";
import { Avatar } from "@files-ui/react";
import { getProfile, updateProfile, updateProfileImage } from "../../../controllers/customer/ProfileController";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";
import { POST_JSON } from "../../../controllers/ConsumeApiController";
import CustomerRoutes from "../../../apiRoutes/CustomerRoutes";
import { imagesURL } from "../../../apiRoutes/apiBase";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

export default function ClientProfile()
{
    const {userData,setUserData}  =useContext(UserContext);
    const [isLoading,setIsLoading] = useState(false);

    const [name,setName] = useState('');
    const [nameError,setNameError] = useState(false);
    const [lastName,setLastName] = useState('');
    const [lastNameError,setLastNameError] = useState(false)
    const [documentType,setDocumentType] = useState('');
    const [document,setDocument] = useState('')
    const [documentError,setDocumentError] = useState(false);
    const [prefix,setPrefix] = useState('');
    const [phoneNumber,setPhoneNumber] = useState('');
    const [phoneNumberError,setPhoneNumberError] = useState(false);

    const [files, setFiles] = useState([]);
    const [imageProfile,setImageProfile] = useState();

    const [show,setShow] = useState(false);
    const [success,setSuccess] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [actionText,setActionText] = useState('Finalizar');
    const [secondaryText,setSecondaryText] = useState('SI');

    useEffect(()=>{
        handleGetUserProfile();
    },[]);

    const handleGetUserProfile = async () => {
        setIsLoading(true);
        let response = await getProfile();
        if(response.success === true)
        {
            setName(response.data.name);
            setLastName(response.data.apellido);
            setDocumentType(response.data.documento_tipo);
            setDocument(response.data.documento);
            setPrefix(response.data.prefijo);
            setPhoneNumber(response.data.telefono);
            setImageProfile(imagesURL+'users/'+response.data.imagen)
        }
        setIsLoading(false);
    }

    const handleChangeImageProfile = async (incommingFiles) => {
        console.log('SELECTED FILE ===> ',incommingFiles);
        setImageProfile(incommingFiles);
        //subir la foto al servidor
        setIsLoading(true);
        let response = await updateProfileImage(incommingFiles);
        if(response.success === true)
        {
            setSuccess(true);
            setTitle('Operación Exitosa');
            setMessage(response.message);
            setShow(true);
        }
        else
        {
            setSuccess(false);
            setTitle('Operación Fallida');
            setMessage(response.message);
            setShow(true);
        }
        setIsLoading(false);
    }
    
    const updateFiles = (incommingFiles) => {
        //do something with the files
        setFiles(incommingFiles);
        //even your own upload implementation
    };
    
    const removeFile = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };

    const formVerification = () => {
        let ban = 0;
        if(name === '')
        {
            setNameError(true);
            ban=1;
        }
        if(lastName === '')
        {
            setLastNameError(true);
            ban = 1
        }
        if(document === '')
        {
            setDocumentError(true);
            ban=1;
        }
        if(phoneNumber === '')
        {
            setPhoneNumberError(true);
            ban=1
        }

        return ban;
    }

    const handleSubmit = async () => {
        if(formVerification() === 1)
            return;

        setIsLoading(true);
        let response = await updateProfile(name,lastName,documentType,document,prefix,phoneNumber);
        if(response.success === true)
        {
            setSuccess(true);
            setTitle('Operación Exitosa');
            setMessage(response.message);
            setShow(true);
        }
        else
        {
            setSuccess(false);
            setTitle('Operación Fallida');
            setMessage(response.message);
            setShow(true);
        }
        setIsLoading(false);
    }

    return(
        <Container maxWidth='lg'>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Perfil',link:'/profile'}]}/>
            <SectionTitle title='Mi perfil'/>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={7} sm={4} md={4} lg={12} xl={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Avatar
                                style={{width:'100%',objectFit:'cover'}}
                                src={imageProfile}
                                
                                alt="Avatar"
                                onChange={handleChangeImageProfile}
                                //value={imageProfile}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant='h6'>Datos personales</Typography>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Nombre"
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    label="Apellido"
                                    value={lastName}
                                    onChange={(e)=>setLastName(e.target.value)}
                                />
                            </Grid>
                            
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <TextField
                                    fullWidth
                                    id="documentType"
                                    name="documentType"
                                    label="Documento"
                                    select
                                    value={documentType}
                                    onChange={(e)=>setDocumentType(e.target.value)}
                                >
                                    <MenuItem value='V'>V</MenuItem>
                                    <MenuItem value='E'>E</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <TextField
                                    fullWidth
                                    id="documentNumber"
                                    name="documentNumber"
                                    label="Número de documento"
                                    value={document}
                                    onChange={(e)=>setDocument(e.target.value)}
                                />
                            </Grid>

                            <Grid item lg={12} mt={2}>
                                <Typography variant='h6'>Datos de Contacto</Typography>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    disabled
                                    id="email"
                                    name="email"
                                    label="Correo electrónico"
                                    value={userData.user.email}
                                />
                            </Grid>

                            {/**Telefono */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <PhoneInput
                                    specialLabel="Número de Teléfono"
                                    inputStyle={{width:'100%'}}
                                    value={phoneNumber}
                                    onChange={(e)=>setPhoneNumber(e)} // passed function receives the phone value
                                />
                            </Grid>
                            

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={()=>handleSubmit()}
                                >
                                    <Typography>Actualizar datos</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={6} xl={6}>
                    <Socials/>
                </Grid>
            </Grid>

            

            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={show}
                success={success}
                title={title}
                message={message}
                actionText={'Aceptar'}
                onClick={()=>setShow(false)}
            />
        </Container>
    )
}
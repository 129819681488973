import { useEffect, useState } from "react";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { GetEvent } from "../../../../controllers/company/EventsController";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { imagesURL } from "../../../../apiRoutes/apiBase";
import QUDProgress from "../../../../components/progress";
import CustomBreadcrumbs from "../../../../components/breadcrumbs";
import EventsSections from "./sections/index";
import SectionTitle from "../../../../components/sectionTitle";
import TicketPDF from "./ticketPdf";
import IssueTicket from "./issue";
import TicketsSold from "./sold";
import QUDProgress2 from "../../../../components/progress2";
import IssueTicketWithSeats from "./issue/withSeats";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
            </Box>
            )}
        </div>
    );
}
  
  
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TicketsTabs(props)
{
    const [value, setValue] = useState(0);

    const [event,setEvent] = useState([]);
    const [exchangeRate,setExchangeRate] = useState(0);
    const [isLoadingData,setIsLoadingData] = useState(false);

    let { id } = useParams();

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(()=>{
        window.scrollTo(0,0);
        handleGetEvent();
    },[]);

    const handleGetEvent = async () => {
        setIsLoadingData(true);
        let response = await GetEvent(id);
        if(response.success === true)
        {
            setEvent(response.data.event);
            setExchangeRate(response.data.exchange_rate);
            //setRutaImg(imagesURL+'/eventos/'+ response.data.imagen);
            console.log(response);
        }
        setIsLoadingData(false)
    }

    return (
        <Container maxWidth='xl'>
            <QUDProgress open={isLoadingData}/>
            <CustomBreadcrumbs
                returnTo='/events'
                crumbs={[{name:'Eventos',link:'/events'},{name:event?.nombre,link:'/events/sections/'+id}]}
            />
            <br/>
            <SectionTitle title={event?.nombre} subtitle='(Gestión de Boleteria)'/>
            <br/>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChangeTab} aria-label="event tab">
                        <Tab label="Secciones" {...a11yProps(0)} />
                        {/*<Tab label="Boleto" {...a11yProps(1)} />*/}
                        <Tab label="Emitir Boletos" {...a11yProps(1)} />
                        <Tab label="Ventas" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                {
                    event.length !== 0 &&
                    <>
                        {/**Secciones del evento */}
                        <CustomTabPanel value={value} index={0}>
                            <EventsSections />
                        </CustomTabPanel>
                        
                        {/**Previw del Boleto 
                        <CustomTabPanel value={value} index={1}>
                            <TicketPDF
                                event={event}
                            />
                        </CustomTabPanel>*/}

                        {/**Emision de Boletos */}
                        <CustomTabPanel value={value} index={1}>
                            {
                                event.event_key !== null
                                ?
                                <IssueTicketWithSeats
                                    event={event}
                                    exchangeRate={exchangeRate}
                                />
                                :
                                <IssueTicket
                                    event={event}
                                    exchangeRate={exchangeRate}
                                />
                            }
                        </CustomTabPanel>

                        {/**Reportes de ventas */}
                        <CustomTabPanel value={value} index={2}>
                            <TicketsSold />
                        </CustomTabPanel>
                    </>
                }
                
            </Box>
        </Container>
    )
    
}
export async function GET(ruta)
{
    try
    {
        var token = 'Bearer '+ localStorage.getItem('AccessToken');

        let response = await fetch(
            ruta,
            {
                method : 'GET',
                headers : 
                {
                    'Accept' : 'application/json',
                    'Authorization' : token,
                }
            }
        );

        let responseJson = await response.json();
        //console.log(responseJson);

        if(responseJson.success === true )
        {
            return responseJson;
        }

        if(responseJson.success === false)
        {
            return responseJson.message;
        }

    }
    catch(error)
    {
        return error;
    }
}


export async function POST(ruta,parametros,type=undefined)
{
    try 
    {
        var token = 'Bearer '+localStorage.getItem('AccessToken');
        let params = new FormData();
        console.log('Parametrros recibidos en POST ===> ',parametros)
        parametros.map((p)=>{
            params.append(p.name,p.value);
        });
        
        let response = await fetch(
            ruta,
            {
                method : 'POST',
                headers : 
                {
                    //'Content-Type': contentType,
                    'Accept' : 'application/json',
                    'Authorization' : token,
                },
                body : params
            }
        );

        let responseJson = await response.json();

        return responseJson;
        
    } 
    catch (error) 
    {
        return error;
    }
}


export async function POST_JSON(ruta,parametros)
{
    try 
    {
        var token = 'Bearer '+localStorage.getItem('AccessToken');

        let response = await fetch(
            ruta,
            {
                method : 'POST',
                headers : 
                {
                    'Content-Type': 'application/json',
                    'Accept' : 'application/json',
                    'Authorization' : token,
                },
                body : JSON.stringify(parametros)
            }
        );
            console.log('POST_JSON RESPONSE ===> ',response)
        let responseJson = await response.json();

        return responseJson;
        
    } 
    catch (error) 
    {
        return error;
    }
}

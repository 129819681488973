import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { BuyTickets, GetEventByID } from "../../../controllers/customer/EventosController";
import { Box, Button, Container, Divider, Fab, Grid, IconButton, MenuItem, Paper, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { imagesURL, stripePromisePK } from "../../../apiRoutes/apiBase";
import QUDProgress from "../../../components/progress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDirections, faMinusCircle, faPhone, faPlusCircle, faTicket, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import {Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import { CreatePaymentIntent } from "../../../controllers/StripeController";
import UserContext from "../../../navigation/context/userContext";
import { deposit } from "../../../controllers/WalletController";
import { today } from "../../../controllers/AuxController";
import CheckoutForm from "../../../components/CheckoutForm";
import TicketsCheckoutForm from "./ticketsCheckOutForm";
import Payments from "../../../components/Payments";
import SignInModal from "../../auth/signInModal";
import ResponseComponent from "../../../components/responseComponent";
import CountdownComponent from "../../../components/CountDownComponent";

import croquis from '../../../assets/images/allies/boxpark/croquisBoxPark.jpg';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import { SeatsioSeatingChart } from '@seatsio/seatsio-react';

const stripePromise = loadStripe(stripePromisePK);


export default function EventsTickets()
{
    const { userData } = useContext(UserContext);
    const [showModal,setShowModal] = useState(true);

    const [event,setEvent] = useState([]);
    const [tickets,setTickets] = useState([]);

    const [sections,setSections] = useState([]);
    const [categories,setCategories] = useState([]);
    
    const [total,setTotal] = useState(100);
    const [subtotal,setSubTotal] = useState(0);
    const [comision,setComision] = useState(0);

    /**customer data */
    const [name,setName] = useState('');
    const [nameError,setNameError] = useState(false);
    const [lastName,setLastName] = useState('');
    const [lastNameError,setLastNameError] = useState(false)
    const [documentType,setDocumentType] = useState('V');
    const [document,setDocument] = useState('');
    const [email,setEmail] = useState('');
    const [emailError,setEmailError] = useState(false);
    const [documentError,setDocumentError] = useState(false);
    const [prefix,setPrefix] = useState('');
    const [phoneNumber,setPhoneNumber] = useState('');
    const [phoneNumberError,setPhoneNumberError] = useState(false);

    const [activeStep,setActiveStep] = useState(0);
    const [showPaymentsForm,setShowPaymentsForm] = useState(false);

    const [isLoading,setIsLoading] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(true);
    const [successA,setSuccessA] = useState(false);
    const [show,setShow] = useState(false);
    const [actionText,setActionText] = useState('Finalizar');
    const [secondaryText,setSecondaryText] = useState('SI');
    const [goBack,setGoBack] = useState(false);

    const [complete,setComplete] = useState(false);

    const [clientSecret,setClientSecret] = useState('');

    const [selectedSeats,setSelectedSeats] = useState([]);
    const [holdToken,setHoldToken] = useState('');

    const { id } = useParams();

    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route)
    }

    const options = {
        // passing the client secret obtained from the server
        //clientSecret: clientSecret?.client_secret,
        mode:'payment',
        currency:'usd',
        amount: 100,
        paymentMethodTypes:['card']
    };

    useEffect(()=>{
        handleGetEvent();
        if(userData.user !== undefined)
        {
            setName(userData.user.name);
            setLastName(userData.user.apellido);
            setDocumentType(userData.user.documento_tipo);
            setDocument(userData.user.documento);
            setPrefix(userData.user.prefijo);
            setPhoneNumber(userData.user.telefono);
            setEmail(userData.user.email);
        }
        window.scrollTo(0,0);
    },[]);

    /*useEffect(()=>{
        calculateUser()
     },[userData]);

     //verificar si el usuario esta logeado
    const calculateUser = () => {
        if(userData.user === undefined)
        {
            setShowModal(true);
        }
        else
        handleOnClose()
    }*/

    const handleGetEvent = async () => {
        setIsLoading(true);
        let response = await GetEventByID(id);
        if(response.success === true)
        {
            setEvent(response.data.event);
            setTickets(response.data.tickets);
            handleSettingTickets(response.data.tickets);
        }
        setIsLoading(false);
    }

    const handleSettingTickets = async (_tickets) => {
        let _sections = [];
        let _categories = [];
        if(_tickets !== undefined)
        {
            await Promise.all(_tickets.map(item =>{
                _sections.push({
                    evento_seccion_id: item.evento_seccion_id,
                    uuid:item.uuid,
                    nombre: item.nombre, //nombre de la categoria
                    descripcion: item.descripcion,
                    precio: item.precio,
                    cantidad: 0,
                    seleccion_max: item.seleccion_max,
                    evento_id: item.evento_id,
                    lista_asientos:[],
                    capacidad: item.capacidad,
                    vendidos: item.vendidos,
                    disponibles: parseInt(item.capacidad) - parseInt(item.vendidos)
                });

                _categories.push({
                    category: item.nombre,
                    price: item.precio
                })
            }));

            setSections(_sections);
            setCategories(_categories);
        }
    }


     /**---------------------------------------------------------------
     *      GESTION DEL "CARRITO DE COMPRAS"                        |
     *--------------------------------------------------------------*/
     const handleShoppingCart = async (_uuid,_operator,_ticket) => {

        setSections((prevSections)=>{
            const updatedSections = [...prevSections];
            const indexToUpdate = updatedSections.findIndex((item)=> item.uuid === _uuid);

            if(indexToUpdate !== -1){
                let _cantidad = updatedSections[indexToUpdate].cantidad;
                
                if(_operator === '+')
                {
                    if(_cantidad < _ticket.disponibles && _cantidad < _ticket.seleccion_max)
                    _cantidad = _cantidad + 1;
                }
                else
                {
                    if(_cantidad > 0)
                    {
                        _cantidad = _cantidad - 1;
                    }
                }

                updatedSections[indexToUpdate].cantidad = _cantidad;
                
                let _lista_asientos = [];
                for(let i=0;i<_cantidad;i++)
                {
                    _lista_asientos.push({fil:updatedSections[indexToUpdate].nombre});
                }
                updatedSections[indexToUpdate].lista_asientos = _lista_asientos;
            }
            return updatedSections;
        })

    }




    const handleTotals = async () => {
        let _subtotal = 0;
        let _comision = 0;
        let _total = 0;
        let _comision_asientos = 0;

        await Promise.all(sections.map(item=>{
            if(event.event_key !== null)
            {
                _comision_asientos += item.cantidad*0.3
            }
            return _subtotal += (item.cantidad * item.precio);
        }));

        _comision = parseFloat(_subtotal*(parseFloat(event.comision)/100));
        _total = parseFloat(_subtotal) + (_subtotal*(parseFloat(event.comision)/100));
        _total = _subtotal + _comision + _comision_asientos;

        console.log('TOTAL ASIENTOS ===> ',_comision_asientos);

        setSubTotal(_subtotal);
        setComision(_comision+_comision_asientos)
        setTotal(_total);
    }

    useEffect(()=>{
        handleTotals();
    },[sections]);

    

    /**--------------------------------------------------
     * Para manejar el paso a paso                      |
     * ------------------------------------------------*/
    const handleNext = async () => {
        if(activeStep === 1)
        {
            if(formVerification() === 1)
                return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        /*if(userData.user === undefined)
            setActiveStep(1);
        else
            setActiveStep(2);*/

    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const formVerification = () => {
        let ban = 0;
        if(name === '')
        {
            setNameError(true);
            ban=1;
        }
        if(lastName === '')
        {
            setLastNameError(true);
            ban = 1
        }
        if(document === '')
        {
            setDocumentError(true);
            ban=1;
        }
        if(phoneNumber.length < 11)
        {
            setPhoneNumberError(true);
            ban=1
        }
        if(email === '')
        {
            setEmailError(true);
            ban=1
        }

        return ban;
    }




  
    const handleSubmit = async (paymentSuccess,payments,automatedPayment=false) => {
        if(paymentSuccess)
        {
            /**reservar los ticket aqui */


            /**Si el proceso es exitoso se continua el proceso,
             * si falla se hace un rollback en seats.io 
             * */
            
            setIsLoading(true);
            const cliente = {
                nombre: name,
                apellido: lastName,
                documento_tipo: documentType,
                documento: document,
                correo:email,
                prefijo:prefix,
                telefono: phoneNumber
            };

            let id_usuario = null;
            if(userData.user !== undefined)
                id_usuario = userData.user.id;
        
            const id_comercio = event.merchant_uuid;
            //const monto = parseFloat(total);
            const monto = parseFloat(subtotal);
            const detalles = sections.filter(section => section.cantidad > 0) ;

            let pagos=[];
            if(payments !== null)
            {
                pagos = [
                    {
                        //uuid: payments.uuid,
                        //paymentMethod: payments.payment_method,
                        //accountName: payments.description,
                        amount: parseFloat(payments.monto),
                        reference: payments.referencia,
                        id_numero_cuenta: payments.id_numero_cuenta
                    }
                ];
            }
    
            
            console.log('Solicitando...')
            let response = await BuyTickets(
                id_usuario,
                id_comercio,
                cliente,
                monto,
                comision,
                detalles,
                pagos,
                automatedPayment,
                holdToken,
                selectedSeats,
                event.event_key
            );
            
            if(response.success === true)
            {
                setTitle('Operación Exitosa');
                setMessage(response.message);
                setSuccessA(true);
                setShow(true);
            }
            else
            {
                setTitle('Operación Fallida! '+message.title);
                setMessage(response.message.message);
                setSuccessA(false);
                setShow(true);
            }
            console.log('RESPONDE ===> ',response);
            setIsLoading(false);
        }
    }


    const handleOnClose = () => {
        setShowModal(false);
        console.log('LOCALSTORAGE ===> ',localStorage.getItem('UserAccount'))
        if(localStorage.getItem('UserAccount') === null)
        {
            setSuccessA(false);
            setTitle('Inicio de sesión obligatorio');
            setMessage('Para poder comprar boletos debe registrarse y/o iniciar sesión');
            setGoBack(true);
            setShow(true);
        }
    }

    const handleClostResponse = () => {
        if(goBack === true)
        {
            history.goBack();
        }
        else
        {
            if(successA === true)
                redireccionar('/');
            else
                setShow(false);
        }
    }

    /*--------------------------------------------------------
    /*          Manejo de la seleccion de asientos           |
    /*------------------------------------------------------*/

    const handleSelectingSeat = async (selectedObject,_operator) => {
        setSections((prevSections)=>{
            const updatedSections = [...prevSections];
            const indexToUpdate = updatedSections.findIndex((item)=> item.nombre === selectedObject.category.label);
            
            if(indexToUpdate !== -1)
            {
                //let _cantidad = updatedSections[indexToUpdate].cantidad;

                if(_operator === '+')
                {
                    const seats = updatedSections[indexToUpdate].lista_asientos;
                    const seatToFind = {fil: selectedObject.labels.parent,col: selectedObject.labels.own}
                    
                    const seatFound = seats.find(seat=>seat.fil === seatToFind.fil && seat.col === seatToFind.col)
                    
                    if(!seatFound)
                        updatedSections[indexToUpdate].lista_asientos.push({
                            fil:selectedObject.labels.parent,col:selectedObject.labels.own
                        });

                    updatedSections[indexToUpdate].cantidad = updatedSections[indexToUpdate].lista_asientos.length;
                }
                else
                {
                        //se filtran los asientos para eliminar el objeto anterior
                        let _asientos = updatedSections[indexToUpdate].lista_asientos.filter(item=>!(item.fil === selectedObject.labels.parent && item.col === selectedObject.labels.own))
                        updatedSections[indexToUpdate].lista_asientos = _asientos;
                        updatedSections[indexToUpdate].cantidad = updatedSections[indexToUpdate].lista_asientos.length;   
                }

                /*updatedSections[indexToUpdate].cantidad = _cantidad;
                updatedSections[indexToUpdate].lista_asientos.push({
                    fil:selectedObject.labels.parent,col:selectedObject.labels.own
                });*/
            }
            console.log('LA SELECCION ES ===> ',updatedSections);
            return updatedSections;
        })
    }



    const handleGoBack = () => {
        if(activeStep === 1)
            handleSettingTickets(tickets)

        setActiveStep(activeStep - 1);
    }



    return(
        <Box mt={-5} pb={5}>
            {/*<SignInModal
                open={showModal}
                onClose={()=>handleOnClose()}
            />*/}
            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={show}
                success={successA}
                title={title}
                message={message}
                actionText={'Aceptar'}
                onClick={()=>handleClostResponse()}
            />

            {
                event.header
                ?
                <Box 
                    sx={{
                        width:'100%',height:{xs:205,sm:250,md:150,lg:300,xl:300},
                        display:'flex',flexDirection:'column',
                        bgcolor:'primary.main',justifyContent:'center',alignItems:'center',
                        backgroundImage: `url(${imagesURL}eventos/${encodeURIComponent(event.header)})`,
                        backgroundPosition:'top',
                        backgroundRepeat:'no-repeat',
                        backgroundSize:'contain'

                    }}
                >
                </Box>
                :
                <Box 
                    sx={{
                        width:'100%',height:300,display:'flex',flexDirection:'column',
                        bgcolor:'primary.main',justifyContent:'center',alignItems:'center'
                    }}
                >
                    <Typography variant='h6' color={'primary.contrastText'} fontWeight={'bold'} textAlign={'center'}>{event.nombre}</Typography>
                    <Typography variant='body1' color={'primary.contrastText'} fontWeight={'bold'} >({event.ciudad})</Typography>
                    
                    <Typography variant='body2' color={'link.main'} fontWeight={'bold'} mt={1}>#EVENTOS #QUD #QUDLIFE</Typography>
                </Box>
            }
            
            <Container>
                <Grid container spacing={3} mt={5}>
                    
                    
                        <Grid item xs={12} sm={12} md={4} lg={5} xl={5}>
                            <Box style={{ width:'100%' }}>
                                <img
                                    style={{width:'100%',borderRadius:20}}
                                    src={imagesURL+'eventos/'+event.imagen}
                                    alt={event.nombre}
                                />
                            </Box>
                        </Grid>
                    
                    
                    <Grid item xs={12} sm={12} md={8} lg={7} xl={7}>
                        {
                            activeStep > 0 &&
                            <IconButton onClick={()=>handleGoBack()}>
                                <FontAwesomeIcon icon={faArrowLeft}/>
                            </IconButton>
                        }

                        <CountdownComponent refreshTime={600000} />
                        <Typography variant="h5" fontWeight={'bold'}>{event.nombre}</Typography>
                        {
                            activeStep === 0 &&
                            <>
                                {/**Seleccion de asientos */}
                                {
                                    event.event_key !== null
                                    ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6">Selecciona los asientos de tu preferencia</Typography>
                                            <Box component={'div'} sx={{width:'100%',height:500}}>
                                                <SeatsioSeatingChart
                                                    workspaceKey="f0117c1e-e399-4ff5-93c2-ee4219d608f5"
                                                    event={event.event_key}
                                                    region="sa"
                                                    pricing={categories}
                                                    priceFormatter={price => '$' + price}
                                                    onObjectSelected={(selectedObject,selectedTicketType) => {
                                                        //console.log('Seleccion de Asiento ===> ',selectedObject);
                                                        //se busca el item compatible con la categoria
                                                        handleSelectingSeat(selectedObject,'+');
                                                        //console.log('Seleccion de Asiento CHART ===> ',selectedObject.chart.holdToken);
                                                        setSelectedSeats(selectedObject.chart.selectedSeats);
                                                        setHoldToken(selectedObject.chart.holdToken);

                                                    }}
                                                    onObjectDeselected={(selectableObject)=>{handleSelectingSeat(selectableObject,'-')}}
                                                    session="continue"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Paper elevation={4}>
                                                <Typography fontWeight={'bold'}>Resumen del pedido</Typography>
                                                <Grid container spacing={3} >
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Box sx={{p:1}}> 
                                                            {
                                                                sections.length !== 0 &&
                                                                sections.map(item=>{
                                                                    if(item.cantidad > 0)
                                                                    return(
                                                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                                            <Typography>{item.nombre} x {item.cantidad}</Typography>
                                                                            <Typography>{parseFloat(item.cantidad*item.precio).toFixed(2)}</Typography>
                                                                        </Box>
                                                                    )
                                                                })
                                                            }
                                                            <Divider sx={{mt:2}}/>
                                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                                <Typography>Subtotal</Typography>
                                                                <Typography>{parseFloat(subtotal).toFixed(2)}</Typography>
                                                            </Box>
                                                        </Box>
                                                        
                                                        <Box sx={{bgcolor:'#F5F8FC',p:1,mt:1,borderRadius:3}}>
                                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                                <Typography>Total</Typography>
                                                                <Typography>{parseFloat(total).toFixed(2)}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    :
                                        <>
                                            <Typography variant="h6" >Boletos</Typography>
                                            {
                                                sections.length !== 0 &&
                                                sections.map((item)=>{
                                                    let borderColor='#FFF';
                                                    let bgColor='#FFF';
                                                    
                                                    if(item.cantidad > 0)
                                                    {
                                                        borderColor = 'success.dark' //lightTheme.colors.success.dark;
                                                        bgColor = 'success.lightTransparency' //lightTheme.colors.success.lightTransparency;
                                                    }
                                                    return(
                                                        <Paper elevation={3} sx={{mt:2,borderColor:borderColor,bgcolor:bgColor}}>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                                                    <Typography variant="h6" fontWeight={'bold'}>
                                                                        {item.nombre}
                                                                    </Typography>
                                                                    <Typography variant="body1">
                                                                        {item.descripcion}
                                                                    </Typography>
                                                                    <Typography variant="h6" fontWeight={'bold'}>
                                                                        {item.precio}&nbsp;USD
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} alignItems={'center'}>
                                                                    {
                                                                        item.vendidos < item.capacidad
                                                                        ?
                                                                        <>
                                                                            <Box display={'flex'} flexDirection={'row'} justifyContent={'end'} alignItems={'center'}>
                                                                                <IconButton onClick={()=>handleShoppingCart(item.uuid,'-',item)}>
                                                                                    <FontAwesomeIcon icon={faMinusCircle}/>
                                                                                </IconButton>
                                                                                <Typography variant="h4" ml={1} mr={1}>{item.cantidad}</Typography>
                                                                                <IconButton onClick={()=>handleShoppingCart(item.uuid,'+',item)}>
                                                                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                                                                </IconButton>
                                                                            </Box>
                                                                            <Typography variant="body1" color='link.main' textAlign={'right'} fontWeight={'bold'}>
                                                                                disponibles = {item.disponibles}
                                                                            </Typography>
                                                                        </>
                                                                        :
                                                                        <Typography variant="h6" color='error.main' textAlign={'right'} fontWeight={'bold'}>
                                                                            Agotado
                                                                        </Typography>
                                                                    }
                                                                </Grid>
                                                            
                                                            </Grid>
                                                        </Paper>
                                                    )
                                                })
                                            }
                                        </>
                                }
                                
                                {
                                    total > 0 &&
                                    <>
                                        <Button variant="contained" sx={{mt:5}} onClick={()=>handleNext()}>
                                            Comprar Boletos
                                        </Button>
                                        
                                    </>
                                }
                            </>
                        }


                        {/**Datos Personales */}
                        {
                            activeStep === 1 &&
                            <>
                                <Grid container spacing={3} mt={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant='h6'>Información del cliente</Typography>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <TextField
                                            fullWidth
                                            id="name"
                                            name="name"
                                            label="Nombre"
                                            value={name}
                                            onChange={(e)=>setName(e.target.value)}
                                            onBlur={ () => (setNameError(name===''?true:false)) }
                                            error = {nameError}
                                            helperText={nameError && 'Ingrese el nombre'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <TextField
                                            fullWidth
                                            id="lastName"
                                            name="lastName"
                                            label="Apellido"
                                            value={lastName}
                                            onChange={(e)=>setLastName(e.target.value)}
                                            onBlur={ () => (setLastNameError(lastName===''?true:false)) }
                                            error = {lastNameError}
                                            helperText={lastNameError && 'Ingrese el apellido'}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            id="documentType"
                                            name="documentType"
                                            label="Documento"
                                            select
                                            value={documentType}
                                            onChange={(e)=>setDocumentType(e.target.value)}
                                        >
                                            <MenuItem value='V'>V</MenuItem>
                                            <MenuItem value='E'>E</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            fullWidth
                                            id="documentNumber"
                                            name="documentNumber"
                                            label="Número de documento"
                                            value={document}
                                            onChange={(e)=>setDocument(e.target.value)}
                                            onBlur={ () => (setDocumentError(document===''?true:false)) }
                                            error = {documentError}
                                            helperText={documentError && 'Ingrese el número de documento'}
                                        />
                                    </Grid>

                                    {/**Telefono */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <PhoneInput
                                            country={'ve'}
                                            specialLabel="Número de Teléfono"
                                            inputStyle={{width:'100%'}}
                                            value={phoneNumber}
                                            onChange={(e)=>setPhoneNumber(e)} // passed function receives the phone value
                                            onBlur={ () =>  (setPhoneNumberError(phoneNumber.length < 11?true:false)) }
                                        />
                                        {
                                            phoneNumberError &&
                                            <Typography variant="caption" color={'error.main'}>El número de teléfono es inválido</Typography>
                                        }
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Correo electrónico"
                                            value={email}
                                            onChange={(e)=>setEmail(e.target.value)}
                                            onBlur={ () => (setEmailError(email===''?true:false)) }
                                            error = {emailError}
                                            helperText={emailError && 'Ingrese el correo electrónico'}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={()=>handleNext()}
                                        >
                                            <Typography>Continuar</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        }

                        {
                            (activeStep === 2 && total > 0) &&
                            <>
                                <Typography variant="h6" >Checkout</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Paper elevation={4}>
                                            <Typography fontWeight={'bold'}>Resumen del pedido</Typography>
                                            <Grid container spacing={3} >
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Box sx={{p:1}}> 
                                                        {
                                                            sections.length !== 0 &&
                                                            sections.map(item=>{
                                                                if(item.cantidad > 0)
                                                                return(
                                                                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                                        <Typography>{item.nombre}</Typography>
                                                                        <Typography>{parseFloat(item.cantidad*item.precio).toFixed(2)}</Typography>
                                                                    </Box>
                                                                )
                                                            })
                                                        }
                                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                            <Typography>Subtotal</Typography>
                                                            <Typography>{parseFloat(subtotal).toFixed(2)}</Typography>
                                                        </Box>
                                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                            <Typography>Comisión QUD</Typography>
                                                            <Typography>{parseFloat(comision).toFixed(2)}</Typography>
                                                        </Box>
                                                    </Box>
                                                    
                                                    <Box sx={{bgcolor:'#F5F8FC',p:1,mt:1,borderRadius:3}}>
                                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                            <Typography>Total</Typography>
                                                            <Typography>{parseFloat(total).toFixed(2)}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Elements stripe={stripePromise} options={options}>
                                                <Payments
                                                    merchantUUID={event.merchant_uuid}
                                                    event_id={event.id}
                                                    customer={{
                                                        nombre: name,
                                                        apellido: lastName,
                                                        documento_tipo: documentType,
                                                        documento: document,
                                                        correo:email,
                                                        prefijo:prefix,
                                                        telefono: phoneNumber
                                                    }}
                                                    amount={parseFloat(parseFloat(total).toFixed(2))}
                                                    handleSubmit={handleSubmit}
                                                    action="Comprar Boletos"

                                                />
                                            </Elements>
                                        </Grid>
                                </Grid>
                            </>
                        }
                        
                    </Grid>
                </Grid>
                
            </Container>
            {
                (total > 0 && activeStep === 0)&&
                <Box
                    onClick={()=>handleNext()}
                    role="presentation"
                    sx={{ position: 'fixed', bottom: 16, right: 16 }}
                >
                    <Fab variant="extended" sx={{bgcolor:'primary.main',color:'primary.contrastText'}}>
                        <FontAwesomeIcon icon={faTicket}/>&nbsp;
                        Comprar Boletos
                    </Fab>
                </Box>
            }
        </Box>
    )
}
import { url } from "./apiBase";

const CustomerRoutes = {
    Cities:
    {
        GetAll: url + 'config/ciudades/all'
    },
    Shops:
    {
        GetCategories: url + 'categorias/all',
        GetAllShopsByCategory: url + 'categorias/all/', //completar con el ID de la categoria
        GetShopByUUID: url + 'public/smartcard/', //completar con el uuid del comercio
        GetShopCategories: url + 'categorias/all/shop/', //completar con el UUID del comercio
        GetShopProductsByCategory: url + 'categorias/all/shop/', //completar con uuid del comercio y uuid de la categoria
    },
    ShoppingCar:{
        AddProduct: url + 'carrito/agregar',
        SearchProduct: url + 'carrito/search/', //completar con el ID del Producto
        GetTotals: url + 'carrito/totales',
        Checkout: url + 'carrito/checkout',
        Details:
        {
            Delete: url + 'carrito/detalle/eliminardetalle',
            ByMerchantID: url + 'carrito/detalle/', //completar con el id del negocio
        }
    },
    Reviews:
    {
        MakeReview: url + 'reviews/create',
    },
    SocialNetwork:
    {
        GetAll: url +'public/redsocial/',
    },
    Products:
    {
        GetProductByUUID: url + 'public/product/', //completar con el UUID
    },
    Events:
    {
        GetAll: url + 'public/events',
        GetEventByID: url + 'public/events/show/', //completar con el ID o el UUID del evento
        GetEventCategories: url + 'public/events/categories',
        GetEventByCategory: url + 'public/events/category/', //completar con el ID de la categoria
        Tickets:
        {
            Buy: url + 'comercios/pedido/boleto',
            Purchased: url + 'clientes/tickets',
            PurchasedByEvent: url + 'clientes/tickets/evento/', //completar con el ID del evento
            GetTicket: url + 'public/events/ticketing/', //completar con el uuid del boleto
        }
    },
    Coupons:
    {
        GetAll: url + 'coupons/allFromCustomer',
        PreloadedCoupon: url + 'public/coupons/free/',//completar con el uuid del cupon precargado
    },
    Orders:
    {
        GetAll: url + 'clientes/pedidos/tipo_pedido/', //completar con el tipo_pedido
        GetDetails: url + 'clientes/pedidos/detalles/', //completar con el codigo del pedido
        TaxiOrder: url + 'clientes/pedidos/crear/taxi',
    },
    Reservations:
    {
        GetAll: url + 'clientes/reservations',
        Create: url + 'clientes/reservations/create',
    },
    Wallet:
    {
        Deposit: url + 'wallet/transaction/depositar',
        DepositWithoutLogin: url + 'public/wallet/transaction/depositar',
        PayWithoutPin: url + 'wallet/transaction/pagarsinpin',
        PayWithoutLogin: url + 'public/wallet/transaction/pagarsl'
    },
    Profile:
    {
        Index: url + 'clientes/profile',
        Update: url + 'clientes/profile/update',
        UpdateImages: url + 'clientes/profile/updateimages',
    }
};


export default CustomerRoutes;
import { useEffect, useState } from "react";
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Container, Divider, Grid, IconButton, Paper, Tab, Typography, useTheme } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useHistory } from "react-router-dom";
import SectionTitle from "../../../components/sectionTitle";
import { GetAll } from "../../../controllers/company/EventsController";
import EventsDataTable from "./dataTable";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faLink, faLocation, faLocationDot, faMapMarked, faShareNodes, faTicket, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { domain, imagesURL } from "../../../apiRoutes/apiBase";
import moment from "moment";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";

export default function Events()
{
    const currentDate = moment();
    const [value, setValue] = useState('1');
    const theme = useTheme();
    const history = useHistory();

    const [events,setEvents] = useState([]);
    const [statistics,setStatistics] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const [show,setShow] = useState(false);
    const [success,setSuccess] = useState(false);
    const [message,setMessage] = useState('');

    const redirect = (route) => {
        history.push(route)
    }
    
    useEffect(()=>{
        handleGetEvents(1);
    },[]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleGetEvents = async (status) => {
        setIsLoading(true);
        let response = await GetAll(status);
        if(response.success === true)
        {
            setEvents(response.data.events);
            setStatistics(response.data.statistics);
        }
        setIsLoading(false);
    }

    const handleCopy = (text) => {
        const url = `${domain}eventos/${text}`;
        navigator.clipboard.writeText(url);
        
        setSuccess(true);
        setMessage('Enlace copiado exitosamente')
        setShow(true);
        //setTimeout(()=>{setCopySuccess(false)},5000)
        //e.clipboardData.setData("Text", 'HOLA MUNDO!');
    }


    
    

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs crumbs={[{name:'Eventos',link:'/events'}]}/>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Grid container spacing={3}>
                            <Grid item lg={10} xl={10}>
                                <Typography variant="h4" component={'h1'} fontWeight={'bold'} gutterBottom>
                                    <FontAwesomeIcon icon={faTicket} color={theme.palette.link.main} />
                                    &nbsp;Panel de eventos
                                </Typography>
                            </Grid>
                            <Grid item lg={2} xl={2}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    endIcon={<AddCircleIcon/>}
                                    onClick={()=>redirect('events/add')}
                                >
                                    <Typography variant="body1">Crear evento</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider/>
                        {
                            statistics.length !== 0 && 
                            <Grid container spacing={3} mt={2} mb={2}>
                            <Grid item lg={6} xl={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6" component={'h2'} fontWeight={'bold'}>Los últimos 7 días</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Typography variant="body1">Ganancia</Typography>
                                        <Typography variant="h5" fontWeight={'bold'}>${parseFloat(statistics?.last_week.ingreso).toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Typography variant="body1">Entradas vendidas</Typography>
                                        <Typography variant="h5" fontWeight={'bold'}>{parseInt(statistics?.last_week.cantidad)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item lg={6} xl={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6" component={'h2'} fontWeight={'bold'}>Este año</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Typography variant="body1">Ganancia</Typography>
                                        <Typography variant="h5" fontWeight={'bold'}>${parseFloat(statistics.last_year.ingreso).toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Typography variant="body1">Entradas vendidas</Typography>
                                        <Typography variant="h5" fontWeight={'bold'}>{parseInt(statistics.last_year.cantidad)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab
                                                value="1"
                                                label={
                                                    <Typography variant="h6" sx={{textTransform:'none',fontWeight:'bold'}}>
                                                        Próximos eventos&nbsp;
                                                        {/*<Chip
                                                            label="1"
                                                            size="small"
                                                            sx={{
                                                                bgcolor:value==='1'?'primary.main':'#eeeeee',
                                                                color:value==='1'?'primary.contrastText':'inherit'
                                                            }}
                                                        />*/}
                                                    </Typography>
                                                }
                                                onClick={()=>handleGetEvents(1)}
                                            />
                                            <Tab
                                                value="2"
                                                label={
                                                    <Typography variant="h6" sx={{textTransform:'none',fontWeight:'bold'}}>
                                                        Eventos finalizados&nbsp;
                                                        {/*<Chip
                                                            label="0"
                                                            size="small"
                                                            sx={{
                                                                bgcolor:value==='2'?'primary.main':'#eeeeee',
                                                                color:value==='2'?'primary.contrastText':'inherit'
                                                            }}
                                                        />*/}
                                                    </Typography>
                                                }
                                                onClick={()=>handleGetEvents(0)}
                                            />
                                        </TabList>
                                    </Box>
                                    
                                    {/**Proximos eventos */}
                                    <TabPanel value={'1'}>
                                        <Grid container spacing={3}>
                                            {
                                                events.length === 0
                                                ?
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography variant="h6" fontWeight={'bold'} gutterBottom mb={2}>
                                                        ¡Organiza tu próximo evento!<br/>Fácil y rápido.
                                                    </Typography>
                                                    <Button
                                                        variant='contained'
                                                        endIcon={<AddCircleIcon/>}
                                                        onClick={()=>redirect('events/add')}
                                                    >
                                                        <Typography variant="body1">Crear evento</Typography>
                                                    </Button>
                                                </Grid>
                                                :
                                                events.map(event=>
                                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                        <Card sx={{maxHeight:'100%'}}>
                                                            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                                                <CardMedia
                                                                    sx={{ height: 200,width:200 }}
                                                                    image={imagesURL+'eventos/'+event.image_path}
                                                                    title={event.name}
                                                                />
                                                            </Box>
                                                            <CardContent>
                                                                <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'}>
                                                                    {event.name}
                                                                </Typography>
                                                                <Typography>
                                                                    <FontAwesomeIcon icon={faCalendarDays}/>&nbsp;
                                                                    {moment(event.date).format('DD/MM/YYYY')} - {moment(currentDate.format('YYYY-MM-DD')+' '+event.time).format('h:mm a')}
                                                                </Typography>
                                                                <Typography>
                                                                    <FontAwesomeIcon icon={faLocationDot}/>&nbsp;
                                                                    {event.city}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions>
                                                                <Button size="large" color="link" onClick={()=>redirect('/events/update/'+event.id)}>
                                                                    <Typography fontWeight={'bold'}>Editar</Typography>
                                                                </Button>
                                                                <Button size="large" color="link" onClick={()=>redirect('/events/tickets/'+event.id)}>
                                                                    <Typography fontWeight={'bold'}>Boletería</Typography>
                                                                </Button>
                                                                <IconButton color="link" onClick={()=>handleCopy(event.id)}>
                                                                    <FontAwesomeIcon icon={faLink}/>
                                                                </IconButton>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                )                                                
                                            }
                                        </Grid>
                                    </TabPanel>


                                    {/**Eventos pasados */}
                                    <TabPanel value={'2'}>
                                        <Grid container spacing={3}>
                                            {
                                                events.length === 0
                                                ?
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography variant="h6" fontWeight={'bold'} gutterBottom mb={2}>
                                                        ¡No hay eventos finalizados!
                                                    </Typography>
                                                    
                                                </Grid>
                                                :
                                                events.map(event=>
                                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                        <Card sx={{maxHeight:'100%'}}>
                                                            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                                                <CardMedia
                                                                    sx={{ height: 200,width:200 }}
                                                                    image={imagesURL+'eventos/'+event.image_path}
                                                                    title={event.name}
                                                                />
                                                            </Box>
                                                            <CardContent>
                                                                <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'}>
                                                                    {event.name}
                                                                </Typography>
                                                                <Typography>
                                                                    <FontAwesomeIcon icon={faCalendarDays}/>&nbsp;
                                                                    {moment(event.date).format('DD/MM/YYYY')} - {moment(currentDate.format('YYYY-MM-DD')+' '+event.time).format('h:mm a')}
                                                                </Typography>
                                                                <Typography>
                                                                    <FontAwesomeIcon icon={faLocationDot}/>&nbsp;
                                                                    {event.city}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions>
                                                                <Button size="large" color="link" onClick={()=>redirect('/events/update/'+event.id)}>
                                                                    <Typography fontWeight={'bold'}>Editar</Typography>
                                                                </Button>
                                                                <Button size="large" color="link" onClick={()=>redirect('/events/tickets/'+event.id)}>
                                                                    <Typography fontWeight={'bold'}>Boletería</Typography>
                                                                </Button>
                                                                <IconButton color="link">
                                                                    <FontAwesomeIcon icon={faShareNodes}/>
                                                                </IconButton>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                )                                                
                                            }
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={'3'}>Item Three</TabPanel>
                                </TabContext>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={show}
                success={success}
                message={message}
                actionText='Finalizar'
                onClick={()=>setShow(false)}
            />
        </Container>
    )
}

/**
 *  <br/>
            <SectionTitle title='Eventos'/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button
                        variant='contained'
                        endIcon={<AddCircleIcon/>}
                        onClick={()=>redirect('events/add')}
                    >
                        <Typography>Eventos</Typography>
                    </Button>
                </Grid>
            </Grid>
            <br/><br/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <EventsDataTable/>
                </Grid>
            </Grid>
 */
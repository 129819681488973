import React, { useContext, useEffect, useState } from 'react';

import MUIDataTable from "mui-datatables";
import { Chip } from '@mui/material';


//export default class TablaProductos extends Component
export default function TicketsSoldDataTable(props)
{
    const [tickets,setEvents] = useState(props.tickets);

    const columns = [
        {
            name : 'codigo',
            label : 'Cod. Pedido',
            
            options:{
                filter:true,
                searchable:true
            }
        },
        {
            name : 'vendedor_nombre',
            label : 'Vendedor',
            options:{
                filter:true,
                //filterType:'dropdown',
                display:true,
                searchable:true
            }
        },
        {
            name : 'cliente',
            label : 'Cliente',
            
            options:{
                filter:true,
                searchable:true
            }
        },
        {
            name : 'seccion',
            label : 'Sección',
            options:{
                filter:true,
                //filterType:'dropdown',
                display:true,
                searchable:true
            }
        },
        {
            name : 'estatus',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 'Disponible'
                    ? <Chip label='Disponible' color='success'/>
                    : <Chip label='Anulado' color='error' />
                )            
            }
        },
        /*{
            name : '',
            label : 'Acciones',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            
                        </div>
                    );
                },

                filter:false,
                print:false
            }
        }*/
    ];



    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <MUIDataTable
        
            title={"Listado de boletos"}
            data={tickets}
            columns={columns}
            options={options}
        />
    )
}
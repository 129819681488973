import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppRoute from './AppRoute';

import ClientHome from '../views/client';
import ClientLayout from '../layouts/DashboardCliente/clientLayout';
import ClientProfile from '../views/client/profile';
import ForgotPassword from '../views/auth/forgotPassword';
import Merchants from '../views/website/merchants';
import MerchantSelect from '../views/website/merchants/select';
import MerchantMenu from '../views/website/merchants/menu';
import MerchantMenuProducts from '../views/website/merchants/menuProducts';
import MerchantProfile from '../views/website/merchants/profile';
import Events from '../views/website/events';
import EventsDetails from '../views/website/events/detail';
import EventsTickets from '../views/website/events/tickets';
import BusinessCard from '../views/website/merchants/businessCard';
import Privacy from '../views/legal/privacy';
import TermsAndConditions from '../views/legal/terms';
import DeleteUser from '../views/legal/deleteUser';
import { WebSiteLayout } from '../layouts/Website/WebSiteLayout';
import Home from '../views/website/home';
import EventsGastromedia from '../views/website/events/gastromedia';
import GastromediaAlly from '../views/website/allies/gastromedia';
import Hamburger from '../views/website/allies/tastyFest/hamburger';
import ClientCoupons from '../views/client/coupons';
import Search from '../views/website/search';
import ClientReservations from '../views/client/reservations';
import TransportBooking from '../views/website/transporte/Booking';
import TransportBooking2 from '../views/website/transporte/Booking2';
import Ticket from '../views/client/events/ticket';
import Artemis from '../views/taxi/Artemis';
import ClientOrders from '../views/client/orders';
import MamaAzul from '../views/website/allies/mamaazul';
import JorgeSilvaCook from '../views/website/allies/exitart/JorgeSilva';
import ChangePassword from '../views/auth/changePassword';



export default function ClientNavigation()
{
 
    
    return(
        <Switch>
            <AppRoute exact path='/' layout={ClientLayout} component={ClientHome} />

            <AppRoute exact path='/transporte' layout={ClientLayout} component={TransportBooking} />
            <AppRoute exact path='/transporte/2' layout={ClientLayout} component={TransportBooking2} />
            <AppRoute exact path='/artemis' layout={WebSiteLayout} component={Artemis} />
            <AppRoute exact path='/mamaazul' layout={ClientLayout} component={MamaAzul} />
            <AppRoute exact path='/Asa2workshop' layout={ClientLayout} component={JorgeSilvaCook} />
            {/**Search */}
            <AppRoute exact path='/search/:category/:location?' layout={ClientLayout} component={Search} />

            {/**coupons */}
            <AppRoute exact path='/coupons' layout={ClientLayout} component={ClientCoupons} />

            {/**reservations */}
            <AppRoute exact path='/reservations' layout={ClientLayout} component={ClientReservations} />


            {/**smart cards */}
            <AppRoute exact path='/smartcards' layout={WebSiteLayout} component={ForgotPassword} />

            <AppRoute exact path='/merchants' layout={ClientLayout} component={Merchants} />
            <AppRoute exact path='/merchants/:uuid' layout={ClientLayout} component={MerchantSelect} />
            <AppRoute exact path='/merchants/:uuid/menu' layout={ClientLayout} component={MerchantMenu} />
            <AppRoute exact path='/merchants/:uuid/menu/:category_uuid' layout={ClientLayout} component={MerchantMenuProducts} />
            <AppRoute exact path='/merchants/:uuid/profile' layout={ClientLayout} component={MerchantProfile} />

            <AppRoute exact path='/eventos' layout={ClientLayout} component={Events} />
            <AppRoute exact path='/eventos/:id' layout={ClientLayout} component={EventsDetails} />
            <AppRoute exact path='/eventos/:id/tickets' layout={ClientLayout} component={EventsTickets} />
            <AppRoute exact path='/eventos/:id/tastyfest/:producto_evento_uuid' layout={ClientLayout} component={EventsGastromedia} />
            <AppRoute exact path='/gastromedia' layout={ClientLayout} component={GastromediaAlly} />
            <AppRoute exact path='/tastyfest/:id' layout={ClientLayout} component={EventsGastromedia} />
            <AppRoute exact path='/ticketing/:uuid' layout={ClientLayout} component={Ticket} />

            {/**Allies */}
            <AppRoute exact path='/tastyfest' layout={ClientLayout} component={Hamburger} />

            
            

            {/**Business Cards */}
            <AppRoute exact path='/businesscard/:uuid' layout={ClientLayout} component={BusinessCard} />

            {/**Legal */}
            <AppRoute exact path='/privacy' layout={ClientLayout} component={Privacy} />
            <AppRoute exact path='/termsandconditions' layout={ClientLayout} component={TermsAndConditions} />
            <AppRoute exact path='/deleteUser' layout={ClientLayout} component={DeleteUser} />



            <AppRoute exact path='/dashboard' layout={ClientLayout} component={ClientHome} />
            <AppRoute exact path='/profile' layout={ClientLayout} component={ClientProfile} />
            <AppRoute exact path='/orders/:tipo_pedido' layout={ClientLayout} component={ClientOrders} />

            <AppRoute exact path='/security/changepassword' layout={ClientLayout} component={ChangePassword} />

            <Route path="*">
                <Redirect path="/"/>
            </Route>
        </Switch>
    )
}

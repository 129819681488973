import { useEffect, useReducer, useState } from "react";
import { Box, Container, Grid, Paper, TextField, Typography, InputAdornment, Checkbox, Link, Button, MenuItem, IconButton, Badge, useTheme, Chip, Avatar } from "@mui/material";
import SectionTitle from "../../components/sectionTitle";


import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import ModalDialog from "../../components/modalDialog";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { companySignUp } from "../../controllers/AuthController";
import { GetToSearchMerchants } from "../../controllers/MerchantsController";

import login_photo from '../../assets/images/website/loginui.png';
import logo from '../../assets/images/logos/black.png';

//imagenes de paises
import chile from '../../assets/images/countries/chile.png';
import colombia from '../../assets/images/countries/colombia.png';
import venezuela from '../../assets/images/countries/venezuela.png';
import usa from '../../assets/images/countries/usa.png';
import elsalvador from '../../assets/images/countries/elsalvador.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import ResponseComponent from "../../components/responseComponent";
import QUDProgress from "../../components/progress";
import PhoneInput from "react-phone-input-2";


const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
    countries:[],           cities:[],
    country:null,              
    categories:[],

    name: '',               errorName:false,
    lastName: '',           errorLastName:false,
    documentType:'',        errorDocumentType:false,
    documentNumber:'',      errorDocumentNumber:false,
    phone:'',               errorPhone:false,
    category:'',            errorCategory:false,
    description:'',         errorDescription:false,
    city:'',                errorCity:false,

    email : '',             errorEmail : false,
    //username:'',            errorUsername: false,
    password : '',          errorPassword : false,
    passwordConfirm : '',   errorPasswordConfirm : false,
    agree:false,            errorAgree: false,
    
    isLoading : false,
    registrado: false,
    errorSignIn : false,
    errorSignInMsg : '',
    redirectToVerify : false,

    isOpen : false,
    result : false,
    message : '',
    title : '',
   
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }
  



export default function CompanySignUp()
{
    const theme = useTheme();
    const [step,setStep] = useState(0);
    const [citiesAux,setCitiesAux] = useState([]);
    const [state,setState] = useReducer(reducer,initialState);
    const {
        countries, country, cities,
        categories,

        name, errorName, lastName, errorLastName,
        documentType, errorDocumentType, documentNumber, errorDocumentNumber,
        phone, errorPhone,
        category, errorCategory, description, errorDescription, city, errorCity,
        email, errorEmail,
        //username, errorUsername,
        password, errorPassword,
        passwordConfirm, errorPasswordConfirm,
        agree, errorAgree,

        isLoading,
        errorSignIn, errorSignInMsg,
        redirectToVerify,
        registrado,

        isOpen,
        title, message, success

    } = state;

    const paises = [
        {id:1, nombre:'Venezuela', simbolo:'VE',imagen:venezuela},
        {id:2, nombre:'Colombia', simbolo:'CO',imagen:colombia},
        {id:3, nombre:'Chile', simbolo:'CL',imagen:chile},
        {id:4, nombre:'USA', simbolo:'USA',imagen:usa},
        {id:5, nombre:'El Salvador', simbolo:'SV',imagen:elsalvador},
    ];

    const paisesDocumentos = [
        {idPais:1,nombre:'RIF (J)',valor:'J'},
        {idPais:1,nombre:'Cédula de identidad Venezolana (V)',valor:'V'},
        {idPais:1,nombre:'Cédula de identidad Extranjera (E)',valor:'E'},
        {idPais:2,nombre:'Número de identificación tributaria (NIT)',valor:'NIT'},
        {idPais:2,nombre:'Cédula de Ciudadanía  (CC)',valor:'CC'},
        {idPais:2,nombre:'Cédula de identidad Extranjería (CE)',valor:'CE'},
        {idPais:3,nombre:'Rol Único Tributario (RUT)',valor:'RUT'},
        {idPais:4,nombre:'EIN',valor:'EIN'},
        {idPais:4,nombre:'SSN',valor:'SSN'},
        {idPais:5,nombre:'DUI',valor:'DUI'},
        {idPais:5,nombre:'NIF',valor:'NIF'},
    ];

    useEffect(()=>{
        handleGetToSearchMerchants();
    },[]);

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }

    const handleGetToSearchMerchants = async () =>{
        let response = await GetToSearchMerchants();
        if(response.success === true)
        {
            setState({field:'categories',value:response.data.categories});
            setState({field:'cities',value:response.data.cities});
        }
    }


    const formVerification = () => {
        var ban = 0;

        if(name === '')
        {
            setState({field:'errorName',value:true});
            ban = 1;
        }

        if(lastName === '')
        {
            setState({field:'errorLastName',value:true});
            ban = 1;
        }

        if(category === '')
        {
            setState({field:'errorCategory',value:true});
            ban = 1;
        }

        if(city === '')
        {
            setState({field:'errorCity',value:true});
            ban = 1;
        }

        if(email === '' /*|| emailReg.test(email) === false*/)
        {
            setState({field:'errorEmail',value:true});
            ban = 1;
        }

        /** 
        if(username === '')
        {
            setState({field:'errorUsername',value:true});
            ban = 1;
        }*/
        
            
        if(password === '')
        {
            setState({field:'errorPassword',value:true});
            ban = 1;
        }

        if(passwordConfirm === '')
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }

        if(password !== passwordConfirm)
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }

        if(agree !== true)
        {
            setState({field:'errorAgree',value:true});
            ban = 1;
        }
        
        return ban;
    }

    const formVerificationStep2 = () => {
        let ban = 0;

        if(name === '')
        {
            setState({field:'errorName',value:true});
            ban = 1;
        }

        if(lastName === '')
        {
            setState({field:'errorLastName',value:true});
            ban = 1;
        }

        if(documentType === '')
        {
            setState({field:'errorDocumentType',value:true});
            ban = 1;
        }

        if(documentNumber === '')
        {
            setState({field:'errorDocumentNumber',value:true});
            ban = 1;
        }

        if(phone.length < 11)
        {
            setState({field:'errorPhone',value:true});
            ban = 1;
        }

        return ban;
    }

    const formVerificationStep3 = () => {
        let ban = 0;

        if(category === '')
        {
            setState({field:'errorCategory',value:true});
            ban = 1;
        }

        if(description === '')
        {
            setState({field:'errorDescription',value:true});
            ban = 1;
        }

        return ban;
    }

    const handleNext = () => {
        if(step === 2)
        {
            let ban = formVerificationStep2();
            if(ban===0)
                setStep(step+1)
        }

        if(step === 3)
        {
            let ban = formVerificationStep3();
            if(ban===0)
                setStep(step+1)
        }
         
    }

    const handlePrevius = () => {
        setStep(step-1);
    }



    const handleSubmit = async () => {
        if(formVerification() === 1)
            return;
            
        setState({field:'isLoading',value:true})
        let response = await companySignUp(name,lastName,documentType,documentNumber,phone,category,description,city.idCiudad,email,password);
        if(response.success === true)
        {
            setState({field:'title',value:'Operacion Exitosa!'});
            setState({field:'message',value:response.message});
            setState({field:'success',value:true});
            setState({field:'isOpen',value:true});
        }
        else if(response.success === false)
        {
            setState({field:'title',value:'Operacion Fallida!'});
            setState({field:'message',value:response.message});
            setState({field:'success',value:false});
            setState({field:'isOpen',value:true});
        }
        else
        {
            setState({field:'title',value:'Operacion Fallida!'});
            setState({field:'message',value:'En estos momentos su solicitud no puede ser procesada!'});
            setState({field:'success',value:false});
            setState({field:'isOpen',value:true});
        }
        
        setState({field:'isLoading',value:false})
    }


    const handleclose = async () => {
        if(title === 'Operacion Exitosa!')
        {
            setState({field:'title',value:''});
            setState({field:'msg',value:''});
            setState({field:'isOpen',value:false});

            redirect('/auth/signIn');
        }
        else
        {
            setState({field:'title',value:''});
            setState({field:'msg',value:''});
            setState({field:'isOpen',value:false});
        }
    }


    /**Seleccion de pais - ciudad */
    const handleSetCountry = (item) => {
        setState({field:'country',value:item});
        let _cities = cities.filter((city)=>city.idPais === item.id);
        setCitiesAux(_cities);
        setState({field:'documentType',value:''});
        setState({field:'documentNumber',value:''});
        setStep(step+1);
    }

    const handleSetCity = (item) => {
        setState({field:'city',value:item});
        setStep(step+1);
    }

    return(
        <Container maxWidth='xl'>
            <Grid container spacing={3}>
                <Grid item lg={5} xl={5} sx={{display:{xs:'none',sm:'none',md:'none',lg:'block',xl:'block'}}} >
                    <Box sx={{width:'100%'}}>
                        <img
                            style={{width:'100%',borderRadius:20}}
                            src={login_photo}
                            alt=""
                        />
                    </Box>
                </Grid>
                <Grid item lg={7} xl={7} display={'flex'} alignItems={'center'}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item lg={7} xl={7} display={'flex'} justifyContent={'center'}>
                            <Box sx={{width:85,height:85,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img
                                    style={{width:85,height:85}}
                                    src={logo}
                                    alt=""
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={12} xl={12}>
                            <Typography variant="h4" fontWeight={'bold'} textAlign={'center'}>Registro de cuenta comercial</Typography>
                        </Grid>
                        <Grid item lg={8} xl={8}>
                            <Grid container spacing={3} mt={3}>
                                {
                                    step === 0 &&
                                    <>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" component={'p'}>Selecciona el país donde está ubicado tu negocio</Typography>
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                                            <Grid container spacing={3} bgcolor={'#f0f0f0'} borderRadius={3} pb={2} display='flex' justifyContent={'center'} alignItems={'center'}>
                                                {
                                                    paises.map(item=>
                                                        <Grid item>
                                                            <Button sx={{display:'flex',flexDirection:'column'}} onClick={()=>handleSetCountry(item)}>
                                                                <img style={{width:70}} src={item.imagen} alt="venezuela"/>
                                                                <Typography>{item.nombre}</Typography>
                                                            </Button>
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                {
                                    step === 1 &&
                                    <>
                                        
                                        <Grid>
                                            <Chip
                                                variant="outlined"
                                                avatar={<Avatar src={country.imagen} />}
                                                label={<Typography variant="h6">{country.nombre}</Typography>}
                                                onDelete={()=>setStep(0)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" component={'p'}>Selecciona la ciudad donde está ubicado tu negocio</Typography>
                                        </Grid>
                                        {
                                            citiesAux.map(item=>
                                                <Grid m={1}>
                                                    <Chip
                                                        label={<Typography variant="h6">{item.nombre}</Typography>}
                                                        onClick={()=>handleSetCity(item)}
                                                        sx={{'&:hover':{bgcolor:'#0C0E11',color:'#fff'}}}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    </>
                                }
                                {
                                    step === 2 &&
                                    <>
                                        <Grid item>
                                            <Chip
                                                variant="outlined"
                                                avatar={<Avatar src={country.imagen} />}
                                                label={<Typography variant="h6">{country.nombre}</Typography>}
                                                onDelete={()=>setStep(0)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                variant="outlined"
                                                avatar={<Avatar sx={{bgcolor:'primary.main'}}><Typography color={'#FFF'}>{city.nombre.charAt(0)}</Typography></Avatar>}
                                                label={<Typography variant="h6">{city.nombre}</Typography>}
                                                onDelete={()=>setStep(1)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} alignItems={'center'} >
                                            <IconButton onClick={()=>handlePrevius()}><FontAwesomeIcon icon={faArrowLeft}/></IconButton>
                                            <Typography variant="h6">Háblanos acerca de tu negocio</Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextField
                                                fullWidth
                                                id="name"
                                                label="Nombre Legal"
                                                //placeholder='Nombre legal de tu negocio o tu nombre completo'
                                                name='name'
                                                variant="outlined"
                                                
                                                value={name}
                                                onChange = { onChange }
                                                onBlur={ () => (name === '' ? setState({field:'errorName',value:true}) : setState({field:'errorName',value:false}) ) }
                                                error = {errorName}
                                                helperText={'Ingresa el nombre legal de tu negocio o tu nombre completo'}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextField
                                                fullWidth
                                                id="lastName"
                                                label="Nombre Comercial"
                                                placeholder='Nombre Comercial'
                                                name='lastName'
                                                variant="outlined"
                                                
                                                value={lastName}
                                                onChange = { onChange }
                                                onBlur={ () => (lastName === '' ? setState({field:'errorLastName',value:true}) : setState({field:'errorLastName',value:false}) ) }
                                                error = {errorLastName}
                                                helperText={errorLastName && 'Ingresa el nombre comercial de tu negocio'}
                                            />
                                        </Grid>

                                        {/**Tipo documento */}
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                fullWidth
                                                id="documentType"
                                                name="documentType"
                                                label="Documento"
                                                select
                                                value={documentType}
                                                onChange = { onChange }
                                                onBlur={ () => setState({field:'errorDocumentType',value:documentType===''?true:false}) }
                                                error = {errorDocumentType}
                                                helperText={errorDocumentType && 'Seleccione el tipo de documento'}
                                            >
                                                {
                                                    paisesDocumentos.filter((item)=>item.idPais === country.id).map(item=>
                                                        <MenuItem value={item.valor}>{item.nombre}</MenuItem>
                                                    )
                                                }          
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                fullWidth
                                                id="documentNumber"
                                                name="documentNumber"
                                                label="Número de documento"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{documentType}&nbsp;<span style={{fontWeight:'bold'}}>-</span></InputAdornment>,
                                                }}
                                                value={documentNumber}
                                                onChange = { onChange }
                                                onBlur={ () => setState({field:'errorDocumentNumber',value:documentNumber===''?true:false}) }
                                                error = {errorDocumentNumber}
                                                helperText={errorDocumentNumber && 'Ingresa el número de documento'}
                                            />
                                        </Grid>

                                        {/**Telefono */}
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <PhoneInput
                                                specialLabel="Número de Teléfono"
                                                inputStyle={{width:'100%'}}
                                                value={phone}
                                                onChange={(e)=>{
                                                    setState({field:'phone',value:e});
                                                    setState({field:'errorPhone',value:phone.length < 10 ? true : false})
                                                }} // passed function receives the phone value
                                                onBlur={ () => (setState({field:'errorPhone',value:phone.length < 10 ? true : false}))}
                                            />
                                            {
                                                errorPhone &&
                                                <Typography color={'error.main'}>El número de teléfono es inválido</Typography>
                                            }
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={()=>handleNext()}
                                            >
                                                Continuar
                                            </Button>
                                        </Grid>
                                    </>
                                }

                                {
                                    step === 3 &&
                                    <>
                                        <Grid item>
                                            <Chip
                                                variant="outlined"
                                                avatar={<Avatar src={country.imagen} />}
                                                label={<Typography variant="h6">{country.nombre}</Typography>}
                                                onDelete={()=>setStep(0)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                variant="outlined"
                                                avatar={<Avatar sx={{bgcolor:'primary.main'}}><Typography color={'#FFF'}>{city.nombre.charAt(0)}</Typography></Avatar>}
                                                label={<Typography variant="h6">{city.nombre}</Typography>}
                                                onDelete={()=>setStep(1)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} alignItems={'center'} >
                                            <IconButton onClick={()=>handlePrevius()}><FontAwesomeIcon icon={faArrowLeft}/></IconButton>
                                            <Typography variant="h6">¿A qué se dedica tu negocio?</Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextField
                                                fullWidth
                                                id="category"
                                                label="Categoría"
                                                placeholder='Selecciona una Categoría'
                                                name='category'
                                                variant="outlined"
                                                select
                                                value={category}
                                                onChange = { onChange }
                                                onBlur={ () => (category === '' ? setState({field:'errorCategory',value:true}) : setState({field:'errorCategory',value:false}) ) }
                                                error = {errorCategory}
                                                helperText={errorCategory && 'Selecciona una categoría'}
                                            >
                                                {categories.map((item) => (
                                                    <MenuItem key={item.idcategoria} value={item.idcategoria}>
                                                    {item.nombre}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextField
                                                fullWidth
                                                id="description"
                                                name="description"
                                                label="Descripción"
                                                placeholder="Ingresa una breve descripción de tu negocio"
                                                multiline
                                                rows={7}
                                                value={description}
                                                onChange = { onChange }
                                                onBlur={ () => setState({field:'errorDescription',value:description===''?true:false}) }
                                                error = {errorDescription}
                                                helperText={errorDescription && 'Ingresa la descripción de tu negocio'}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={()=>handleNext()}
                                            >
                                                Continuar
                                            </Button>
                                        </Grid>
                                    </>
                                }

                                {
                                    step === 4 &&
                                    <>
                                        <Grid item>
                                            <Chip
                                                variant="outlined"
                                                avatar={<Avatar src={country.imagen} />}
                                                label={<Typography variant="h6">{country.nombre}</Typography>}
                                                onDelete={()=>setStep(0)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                variant="outlined"
                                                avatar={<Avatar sx={{bgcolor:'primary.main'}}><Typography color={'#FFF'}>{city.nombre.charAt(0)}</Typography></Avatar>}
                                                label={<Typography variant="h6">{city.nombre}</Typography>}
                                                onDelete={()=>setStep(1)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} alignItems={'center'} >
                                            <IconButton onClick={()=>handlePrevius()}><FontAwesomeIcon icon={faArrowLeft}/></IconButton>
                                            <Typography variant="h6">Datos de acceso</Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextField
                                                fullWidth
                                                id="email"
                                                label="Correo electrónico"
                                                name='email'
                                                variant="outlined"
                                                value={email}
                                                onChange = { onChange }
                                                onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                                                error = {errorEmail}
                                                helperText={errorEmail && 'Ingrese el correo electrónico'}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextField
                                                fullWidth
                                                id="password"
                                                name='password'
                                                label="Contraseña"
                                                variant="outlined"
                                                type="password"
                                                value={password}
                                                onChange = { onChange }
                                                onBlur={ () => (password === '' ? setState({field:'errorPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                                                error = {errorPassword}
                                                helperText={errorPassword && 'Ingrese la contraseña'}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextField
                                                fullWidth
                                                id="passwordConfirm"
                                                name='passwordConfirm'
                                                label="Confirmar Contrasena"
                                                variant="outlined"
                                                type="password"
                                                value={passwordConfirm}
                                                onChange = { onChange }
                                                onBlur={ () => (passwordConfirm === '' ? setState({field:'errorPasswordConfirm',value:true}) : setState({field:'errorPasswordConfirm',value:false}) ) }
                                                error = {errorPasswordConfirm}
                                                helperText = {(password !== '' && passwordConfirm === '') && 'Ingrese la Confirmación de Contraseña' || passwordConfirm !== password && 'La contraseña no coincide'}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <Checkbox
                                                checked={agree} 
                                                onChange={()=>{ 
                                                    setState({field:'agree',value:!agree})} 
                                                }
                                                name="agree"
                                                id='agree' 
                                                onBlur={ () => (agree === false ? setState({field:'errorAgree',value:true}) : setState({field:'errorAgree',value:false}) ) }
                                            />
                                            <Typography>
                                                Declaro que he leído y acepto las&nbsp;
                                                <Link sx={{color:'link.main',textDecoration:'none'}} component='a' href='https://qud.life/privacy' target='_blank'>
                                                    Políticas de Privacidad
                                                </Link> 
                                                &nbsp;así como los&nbsp;
                                                <Link sx={{color:'link.main',textDecoration:'none'}} component='a' href='https://qud.life/termsandconditions' target='_blank'>
                                                    Términos y Condiciones
                                                </Link> 
                                                &nbsp;de uso de QUD. 
                                            </Typography>
                                        </Grid>
                                        {
                                            errorAgree === true
                                            ?<Typography style={{color:'#ff0000'}}>Debe leer y aceptar los Términos y Condiciones de uso de QUD</Typography>
                                            :null
                                        }
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                onClick={()=>handleSubmit()}
                                            >
                                                <Typography fontWeight={'bold'}>Registrarme</Typography>
                                            </Button>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ResponseComponent 
                show={isOpen}
                title={title}
                success={success}
                message={message}
                handleClose={()=>handleclose()}
                actionText="Aceptar"
                onClick={()=>handleclose()}
                action='Finalizar'
            />
            <QUDProgress open={isLoading}/>
        </Container>
    )
}

/**
 * <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                pb:5
            }}
        >
        <Container maxWidth='md'>
            <SectionTitle title='Registro de Empresa'/>
            
            <Paper elevation={6} sx={{pt:5,pb:5}}>
                <Box
                    component='form'
                    
                    spacing={2}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                           
                            <TextField
                                fullWidth
                                id="name"
                                label="Nombre Legal"
                                placeholder='Nombre Legal'
                                name='name'
                                variant="outlined"
                                
                                value={name}
                                onChange = { onChange }
                                onBlur={ () => (name === '' ? setState({field:'errorName',value:true}) : setState({field:'errorName',value:false}) ) }
                                error = {errorName}
                                helperText={errorName && 'Ingrese tu nombre'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            
                            <TextField
                                fullWidth
                                id="lastName"
                                label="Nombre Comercial"
                                placeholder='Nombre Comercial'
                                name='lastName'
                                variant="outlined"
                                
                                value={lastName}
                                onChange = { onChange }
                                onBlur={ () => (lastName === '' ? setState({field:'errorLastName',value:true}) : setState({field:'errorLastName',value:false}) ) }
                                error = {errorLastName}
                                helperText={errorLastName && 'Ingresa tu apellido'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            
                            <TextField
                                fullWidth
                                id="category"
                                label="Categoría"
                                placeholder='Selecciona una Categoría'
                                name='category'
                                variant="outlined"
                                select
                                value={category}
                                onChange = { onChange }
                                onBlur={ () => (category === '' ? setState({field:'errorCategory',value:true}) : setState({field:'errorCategory',value:false}) ) }
                                error = {errorCategory}
                                helperText={errorCategory && 'Selecciona una categoría'}
                            >
                                {categories.map((item) => (
                                    <MenuItem key={item.idcategoria} value={item.idcategoria}>
                                    {item.nombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                           
                            <TextField
                                fullWidth
                                id="city"
                                label="Ciudad"
                                placeholder='Selecciona una ciudad'
                                name='city'
                                variant="outlined"
                                select
                                value={city}
                                onChange = { onChange }
                                onBlur={ () => (city === '' ? setState({field:'errorCity',value:true}) : setState({field:'errorCity',value:false}) ) }
                                error = {errorCity}
                                helperText={errorCity && 'Selecciona una ciudad'}
                            >
                                {cities.map((item) => (
                                    <MenuItem key={item.idCiudad} value={item.idCiudad}>
                                    {item.nombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                       
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                id="email"
                                label="Correo"
                                placeholder='Correo'
                                name='email'
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                    ),
                                }}
                                value={email}
                                onChange = { onChange }
                                onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                                error = {errorEmail}
                                helperText={errorEmail && 'Ingrese el correo electronico'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                id="password"
                                name='password'
                                label="Contraseña"
                                placeholder='Contraseña'
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <PasswordIcon />
                                    </InputAdornment>
                                    ),
                                }}
                                type="password"
                                value={password}
                                onChange = { onChange }
                                onBlur={ () => (password === '' ? setState({field:'errorPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                                error = {errorPassword}
                                helperText={errorPassword && 'Ingrese la contraseña'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                id="passwordConfirm"
                                name='passwordConfirm'
                                label="Confirmar Contrasena"
                                placeholder='Confirmar Contrasena'
                                variant="outlined"
                                    InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <PasswordIcon />
                                    </InputAdornment>
                                    ),
                                }}
                                type="password"
                                value={passwordConfirm}
                                onChange = { onChange }
                                onBlur={ () => (passwordConfirm === '' ? setState({field:'errorPasswordConfirm',value:true}) : setState({field:'errorPasswordConfirm',value:false}) ) }
                                error = {errorPasswordConfirm}
                                helperText = {(password !== '' && passwordConfirm === '') && 'Ingrese la Confirmación de Contraseña' || passwordConfirm !== password && 'La contraseña no coincide'}
                            />
                        </Grid>

                    </Grid>
                    

                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:30}}>
                        <Checkbox
                                checked={agree} 
                                onChange={()=>{ 
                                    setState({field:'agree',value:!agree})} 
                                }
                                name="agree"
                                id='agree' 
                                onBlur={ () => (agree === false ? setState({field:'errorAgree',value:true}) : setState({field:'errorAgree',value:false}) ) }
                            />
                        <Typography>
                            Declaro que he leído y acepto las&nbsp;
                            <Link sx={{color:'link.main',textDecoration:'none'}} component='a' href='https://qud.life/privacy' target='_blank'>
                                Políticas de Privacidad
                            </Link> 
                            &nbsp;así como los&nbsp;
                            <Link sx={{color:'link.main',textDecoration:'none'}} component='a' href='https://qud.life/termsandconditions' target='_blank'>
                                Términos y Condiciones
                            </Link> 
                            &nbsp;de uso de QUD. 
                        </Typography>
                    </div>
                    {
                        errorAgree === true
                        ?<Typography style={{color:'#ff0000'}}>Debe leer y aceptar los Términos y Condiciones de uso de QUD</Typography>
                        :null
                    }
                    <br/>
                    <LoadingButton
                        fullWidth
                        variant='contained'
                        loading={isLoading}
                        onClick={()=>handleSubmit()}
                    >
                        <Typography fontWeight={'bold'}>Registrarme</Typography>
                    </LoadingButton>
                        
                </Box>
            </Paper>

            <ModalDialog
                open={isOpen}
                title={title}
                msg={msg}
                handleClose={()=>handleclose()}
                handleAction={()=>handleclose()}
                action='Finalizar'
            />
        </Container>
        </Box>
 */
import { Container, Box, Tab, Grid, Paper, Avatar, Typography, Button, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { imagesURL } from "../../../apiRoutes/apiBase";

export default function Reservation(props)
{
    return(
        <Grid item lg={12}>
            <Paper elevation={4}>
                <Grid container spacing={3} display={'flex'} alignItems={'center'}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Avatar
                                    alt="customer"
                                    src={imagesURL+'users/'+props.src}
                                    sx={{ width: 100, height: 100 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                <Typography textAlign={'center'} variant="h6" fontWeight={'bold'}>
                                    {props.customer}
                                </Typography>
                                <Typography textAlign={'center'} variant="body1" mb={1}>
                                    {props.phone_number}
                                </Typography>
                                <Button
                                    color='success'
                                    variant="text"
                                    startIcon={<FontAwesomeIcon icon={faWhatsapp}/>}
                                    
                                >
                                    <Typography fontWeight={'bold'}>Contactar</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box sx={{display:'flex',flexDirection:'row'}}>
                                    <Typography>Personas: </Typography>
                                    <Typography fontWeight={'bold'} ml={1}>{props.people}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Box sx={{display:'flex',flexDirection:'row'}}>
                                    <Typography>Fecha: </Typography>
                                    <Typography fontWeight={'bold'} ml={1}>{props.date}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Box sx={{display:'flex',flexDirection:'row'}}>
                                    <Typography>Hora: </Typography>
                                    <Typography fontWeight={'bold'} ml={1}>{props.time}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box sx={{display:'flex',flexDirection:'column'}}>
                                    <Typography>Observaciones: </Typography>
                                    <Typography fontWeight={'bold'} ml={1}>
                                        {props.observations}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    {
                        props.type !== 'informative' &&
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Grid
                                container
                                spacing={3}
                                sx={{display:'flex',flexDirection:{xs:'column',sm:'column',md:'row',lg:'column',xl:'column'},justifyContent:'center'}}
                            >
                                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                    <Button fullWidth variant='outlined' color='error' onClick={props.reject}>
                                        <Typography fontWeight={'bold'}>
                                            Rechazar
                                        </Typography>
                                    </Button>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                    <Button fullWidth variant='contained' onClick={props.approve}>
                                        <Typography fontWeight={'bold'}>
                                            Aprobar reservación
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    
                </Grid>
            </Paper>
        </Grid>
    )
}
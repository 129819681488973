import { useEffect, useState } from 'react';
import { Container, Box, Tab, Grid, Paper, Avatar, Typography, Button, IconButton, Divider, useTheme } from '@mui/material';
import {TabContext, TabList, TabPanel }from '@mui/lab';
import CustomBreadcrumbs from '../../../components/breadcrumbs'
import SectionTitle from '../../../components/sectionTitle'

import { approve, getToBeApproved } from '../../../controllers/company/ReservationsController';
import Reservation from './reservation';
import ResponseComponent from '../../../components/responseComponent';
import ApprovedReservations from './approved';
import QUDProgress from '../../../components/progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function CompanyReservations()
{
    const [value, setValue] = useState('1');
    const [toBeApproved,setToBeApproved] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const [open,setOpen] = useState(false);
    const [success,setSuccess] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState();
    const [actionText,setActionText] = useState('');

    const theme = useTheme();
    const history = useHistory();

    useEffect(()=>{
        handleGetToBeApproved();
    },[])

    const redirect = (route) => {
        history.push(route)
    }
    


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleGetToBeApproved = async () => {
        setIsLoading(true);
        const response = await getToBeApproved();
        console.log('RESERVACIONES ===> ',response.data);
        if(response.success === true)
        {
            setToBeApproved(response.data);
        }
        setIsLoading(false);
    }

    const handleApproveReservation = async (uuid,status) => {
        setIsLoading(true);
        let response = await approve(uuid,status);
        if(response.success === true)
        {
            setTitle('Operación Exitosa!');
            setSuccess(true);
            setMessage(response.message);
            setActionText('Finalizar');
            setOpen(true);
        }
        else
        {
            setTitle('Operación Fallida!');
            setSuccess(false);
            setMessage(response.message);
            setActionText('Finalizar');
            setOpen(true);
        }
        setIsLoading(false);
    }

    const primaryAction = () => {
        setOpen(false);
        handleGetToBeApproved();
    }

    const handleDateClick = (arg) => {
        alert(arg.dateStr)
      }
    

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Reservaciones',link:'reservations'}]}/>
            
            <br/>
            
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Grid container spacing={3}>
                            <Grid item lg={10} xl={10}>
                                <Typography variant="h4" component={'h1'} fontWeight={'bold'} gutterBottom>
                                    <FontAwesomeIcon icon={faCalendarAlt} color={theme.palette.link.main} />
                                    &nbsp;Panel de reservaciones
                                </Typography>
                            </Grid>
                            <Grid item lg={2} xl={2}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    endIcon={<AddCircleIcon/>}
                                    onClick={()=>redirect('events/add')}
                                >
                                    <Typography variant="body1">Crear reservación</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider/>
                        {/**ESTADISTICAS AQUI */}
                        <Grid container spacing={3} mt={2} mb={2}>
                            <Grid item lg={6} xl={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6" component={'h2'} fontWeight={'bold'}>AQUI VAN LAS ESTADISTICAS</Typography>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </Paper>
                </Grid>


                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FullCalendar
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                        //weekends={false}
                        events={[
                            { title: 'Reservacion 1', date: '2024-09-09' },
                            { title: 'Reservacion 2', date: '2024-09-10' }
                        ]}
                    />
                </Grid>
            </Grid>

            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Por Revisar" value="1" />
                    <Tab label="Aprobadas" value="2" />
                    <Tab label="Historico" value="3" />
                </TabList>
                </Box>
                <TabPanel value="1">
                    <Grid container spacing={3}>
                        {
                            toBeApproved.length !== 0
                            ?
                            toBeApproved.map(item=>
                                <Reservation
                                    uuid={item.uuid}
                                    customer={item.customer}
                                    people={item.people}
                                    date={item.date}
                                    time={item.time}
                                    observations={item.observations}
                                    reject={()=>handleApproveReservation(item.uuid,0)}
                                    approve={()=>handleApproveReservation(item.uuid,2)}
                                />
                            )
                            :
                            <Typography mt={5}>No se encontraron resultados</Typography>
                        }
                        <QUDProgress open={isLoading}/>
                    </Grid>
                </TabPanel>
                <TabPanel value="2">
                    <ApprovedReservations/>
                </TabPanel>
                <TabPanel value="3"> <FontAwesomeIcon icon={faTriangleExclamation} size='2x'/>&nbsp; Historico de reservaciones en construcción</TabPanel>
            </TabContext>

            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}

                onClick={()=>primaryAction()}
                actionText={actionText}
            />
        </Container>
    )
}
import { useEffect, useState } from 'react';
import { Container, Box, Tab, Grid, Paper, Avatar, Typography, Button, IconButton } from '@mui/material';
import {TabContext, TabList, TabPanel }from '@mui/lab';
import CustomBreadcrumbs from '../../../components/breadcrumbs'
import SectionTitle from '../../../components/sectionTitle'

import { approve, approved, getToBeApproved } from '../../../controllers/company/ReservationsController';
import Reservation from './reservation';
import ResponseComponent from '../../../components/responseComponent';
import QUDProgress from '../../../components/progress';


export default function ApprovedReservations()
{
    const [reservations,setReservations] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        handleGetReservations();
    },[]);


    const handleGetReservations = async () => {
        setIsLoading(true);
        const response = await approved();
        if(response.success === true)
        {
            setReservations(response.data);
        }
        setIsLoading(false);
    }

    return(
        <Grid container spacing={3}>
            {
                reservations.length !== 0 &&
                reservations.map(item=>
                    <Reservation
                        uuid={item.uuid}
                        customer={item.customer}
                        people={item.people}
                        date={item.date}
                        time={item.time}
                        observations={item.observations}
                        type='informative'
                    />
                )
            }
            <QUDProgress open={isLoading}/>
        </Grid>
    )
}
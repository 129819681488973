import { useEffect, useState } from "react";
import { Avatar, Box, Card, CardActionArea, CardContent, Container, Grid, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import { imagesURL } from "../../../apiRoutes/apiBase";
import SectionTitle from "../../../components/sectionTitle";
import { GetMerchantCategories } from "../../../controllers/MerchantsController";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ProfileImage from "../../../components/profileImage";
import RegisterDialog from "../../../components/registerDialog";
import ImageButtonQUD from "../../../components/ImageButton";
import QUDProgress from "../../../components/progress";

export default function MerchantMenu()
{
    const [height,setHeight] = useState(250);
    const [merchant,setMerchant] = useState([]);
    const [categories,setCategories] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const history = useHistory();
    const {uuid} = useParams();

    useEffect(() => {
        handleGetCategories();
    },[]);


    function redireccionar(ruta)
    {
        history.push(ruta);
    }

    const handleGetCategories = async () => {
        setIsLoading(true);
        let response = await GetMerchantCategories(uuid);
        if(response.success === true)
        {
            setMerchant(response.data.merchant);
            setCategories(response.data.categorias);
        }
        setIsLoading(false);
    }

    


    return(
        <Box>
            <Container sx={{pb:5,mt:7}}>
                
                <IconButton onClick={()=>{redireccionar('/merchants/'+uuid+'/profile')}}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                </IconButton>
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <Avatar
                        variant="rounded"
                        sx={{width:150,height:150}}
                        src={imagesURL+'empresas/'+merchant.imagen2}
                        alt={merchant.merchant}
                    />
                </Box>
                <Grid container spacing={3} mt={1} mb={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h4" fontWeight={'bold'} textAlign={'center'}>
                            {merchant.merchant}
                        </Typography>
                        <Typography variant="h6" textAlign={'center'} mt={0}>Selecciona una categoría</Typography>
                    </Grid>
                </Grid>
                {/*<SectionTitle title={merchant.merchant} subtitle='Selecciona una categoría' mt={0}/>*/}
                
                <Grid container spacing={3} justifyContent='center'>
                {
                    categories.length !== 0 &&
                    categories.map(item=>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <ImageButtonQUD
                                title={item.nombre}
                                width={'100%'}
                                url={imagesURL+'subcategorias/'+item.imagen}
                                onClick={()=>redireccionar('/merchants/'+uuid+'/menu/'+item.uuid)}
                            />
                        </Grid>
                    )
                }
                </Grid>
            </Container>

            <RegisterDialog open={false}/>
            <QUDProgress open={isLoading}/>
        </Box>
    )
}
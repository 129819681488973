import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import { GET, POST } from "../ConsumeApiController";

export async function getToBeApproved()
{
    const response = await GET(CompanyRoutes.Reservations.GetToBeApproved);
    return response;
}

export async function approve(uuid,status)
{
    const params = [
        {name:'uuid',value:uuid},
        {name:'status',value:status}
    ];

    const response = await POST(CompanyRoutes.Reservations.Approve,params);
    return response;
}

export async function approved(uuid,status)
{
    const response = await GET(CompanyRoutes.Reservations.Approved);
    return response;
}

export async function historical(uuid,status)
{
    const response = await GET(CompanyRoutes.Reservations.historical);
    return response;
}
import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import { GET, POST, POST_JSON } from "../ConsumeApiController";


export async function getDataToStore()
{
    let response = await GET(CompanyRoutes.Events.ToStore);
    return response;
}

export async function Add(
    nombre,dirigidoa,edad,tipoevento,asientos,fecha,hora_apertura,hora,
    idcategoria,descripcion,imagen,youtube,idciudad,direccion,latitud,longitud,boleteria,tickets)
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'dirigidoa',value:dirigidoa},
        {name:'edad',value:edad},
        {name:'tipoevento',value:tipoevento},
        {name:'asientos',value:asientos},
        {name:'fecha',value:fecha},
        {name:'hora_apertura',value:hora_apertura},
        {name:'hora',value:hora},

        {name:'idcategoria',value:idcategoria},
        {name:'descripcion',value:descripcion},
        {name:'imagen',value:imagen},
        {name:'youtube',value:youtube},
        
        {name:'idciudad',value:idciudad},
        {name:'direccion',value:direccion},
        {name:'latitud',value:latitud},
        {name:'longitud',value:longitud},

        {name:'boleteria',value:boleteria},
        {name:'tickets',value:JSON.stringify(tickets)},
       
    ];

    let response = await POST(CompanyRoutes.Events.Add,params);
    return response;
}


export async function GetAll(status)
{
    let response = await GET(CompanyRoutes.Events.GetAll+status);
    return response;
}

export async function GetEvent(id)
{
    let response = await GET(CompanyRoutes.Events.Show+id);
    return response;
}

export async function Update(nombre,dirigidoa,edad,tipoevento,asientos,fecha,hora_apertura,hora,
    idcategoria,descripcion,imagen,youtube,idciudad,direccion,latitud,longitud,boleteria,id)
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'dirigidoa',value:dirigidoa},
        {name:'edad',value:edad},
        {name:'tipoevento',value:tipoevento},
        {name:'asientos',value:asientos},
        {name:'fecha',value:fecha},
        {name:'hora_apertura',value:hora_apertura},
        {name:'hora',value:hora},

        {name:'idcategoria',value:idcategoria},
        {name:'descripcion',value:descripcion},
        {name:'imagen',value:imagen},
        {name:'youtube',value:youtube},
        
        {name:'idciudad',value:idciudad},
        {name:'direccion',value:direccion},
        {name:'latitud',value:latitud},
        {name:'longitud',value:longitud},

        {name:'boleteria',value:boleteria},
        {name:'id',value:id},
       
    ];

    let response = await POST(CompanyRoutes.Events.Update+id,params);
    return response;
}

export async function ChangeStatus(id)
{
    let response = await GET(CompanyRoutes.Events.ChangeStatus+id);
    return response;
}


/**
 * SECCIONES DEL EVENTO
 */
export async function SectionCreate(evento_id,nombre,descripcion,capacidad,precio,multiplos)
{
    const params = [
        {name:'evento_id',value:evento_id},
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'capacidad',value:capacidad},
        {name:'precio',value:precio},
        {name:'multiplos',value:multiplos},
    ];

    let response = await POST(CompanyRoutes.Events.Sections.Create,params);
    return response; 
}

export async function SectionUpdate(uuid,nombre,descripcion,capacidad,precio,multiplos)
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'capacidad',value:capacidad},
        {name:'precio',value:precio},
        {name:'multiplos',value:multiplos},
    ];

    let response = await POST(CompanyRoutes.Events.Sections.Update+uuid,params);
    return response; 
}

export async function SectionList(evento_id)
{
    let response = await GET(CompanyRoutes.Events.Sections.List+evento_id);
    return response;
}

export async function SectionChangeStatus(uuid)
{
    let response = await GET(CompanyRoutes.Events.Sections.ChangeStatus+uuid);
    return response;
}

/**Preview del boleto */
/**
 * 
 * @param {*} evento_id 
 * @param {*} tipo 0 para Header y 1 para Body
 * @param {*} imagen imagen que se va a subir
 */
export async function CreatePreview(evento_id,tipo,imagen)
{
    const params = [
        {name:'evento_id',value:evento_id},
        {name:'tipo',value:tipo},
        {name:'imagen',value:imagen},
    ];
    let response = await POST(CompanyRoutes.Events.Tickets.Create,params);
    return response;
}

export async function GetPreview(evento_id)
{
    let response = await GET(CompanyRoutes.Events.Tickets.GetImages+evento_id);
    return response;
}


export async function IssueTicketsAtBoxOffice(
    from,
    id_evento,id_usuario,id_comercio,cliente,monto=0,comision,
    detalles,pagos,
    holdToken,selectedTickets,eventKey,
    isComplementary=false,
    complimentaryCode='',
    automatedPayment=true,tipo_pedido=1,
    
)
{
    let response = await POST_JSON(
        CompanyRoutes.Events.Tickets.Issue,
        {
            from,
            id_evento,
            id_usuario,
            id_comercio,
            cliente,
            monto,
            comision,
            detalles,
            pagos,
            holdToken,selectedTickets,eventKey,
            isComplementary,
            complimentaryCode,
            automatedPayment,
            tipo_pedido,
        }
    );
    return response;
}

export async function GetTicketsSoldByEvent(evento_id)
{
    let response = await GET(CompanyRoutes.Events.Tickets.SoldByEvent+evento_id);
    return response;
}


/**------------------------------------------------------------
 *                          SPONSORS                          |
 **-----------------------------------------------------------*/
export async function CreateSponsor(evento_id,imagen,url)
{
    const params = [
        {name:'evento_id',value:evento_id},
        {name:'imagen',value:imagen},
        {name:'url',value:url},
    ];

    let response = await POST(CompanyRoutes.Events.Sponsors.Create,params);
    return response;
}

export async function GetSponsorsByEvent(event)
{
    let response = await GET(CompanyRoutes.Events.Sponsors.GetAllByEvent+event);
    return response;
}




/**------------------------------------------------------------
 *                       GASTROMEDIA                          |
 **-----------------------------------------------------------*/
export async function GetGastromediaCompetitor(evento_id)
{
    const response = await GET(CompanyRoutes.Events.Gastromedia.GetCompetitor+evento_id);
    return response;
}

export async function GetProductsEvent(evento_id)
{
    const response = await GET(CompanyRoutes.Events.Products.GetProducts+evento_id);
    return response;
}

export async function SetProductEvent(evento_id,producto_id)
{
    const params = [
        {name:'evento_id',value:evento_id},
        {name:'producto_id',value:producto_id}
    ];

    const response = await POST(CompanyRoutes.Events.Products.SetProductEvent,params);
    return response;
}
